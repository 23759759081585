import React, {useEffect, useState} from "react";
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import api from "../utils/api";

import {Line} from "react-chartjs-2";

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";

import {useCookies} from "react-cookie";

export default function WeightGraphDiary(props) {
	const { t } = useTranslation();
    const { language } = useI18next(); 
	const [graphs, setGraphs] = useState([]);
	const [loading, setLoading] = useState("");
	const [dietPhase, setDietPhase] = useState("");
	const [onAverage, setOnAverage] = useState("");
	const [stabilizationWeight, setStabilizationWeight] = useState("");

	const [cookies] = useCookies(["token", "id"]);
	const [token] = useState(cookies["token"]);
	const [idUser] = useState(cookies["id"]);

	ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		Title,
		Tooltip,
		Legend
	);

	const labels = graphs.map((graph) => graph.x);
	const options = {
		plugins: {
			legend: {
				display: false,
			},
		},
		maintainAspectRatio: false,
	};

	const data = {
		labels,
		datasets: [
			{
				label: "",
				data: graphs.map((graph) => graph.y),
				borderColor: "#7AC142",
				backgroundColor: "#7AC142",
			},
		],
	};

	useEffect(() => {
		api.getWeightGraph(token, {id: idUser}, language).then((res) => {
			setStabilizationWeight(res.dietSummaryStabilizationWeightValue);
			setLoading(res.dietSummaryLoadingValue);
			setDietPhase(res.dietSummaryDietPhaseValue);
			setOnAverage(res.dietSummaryAverageLossValue);
			setGraphs(res.weightChartData);
		}, language);
	}, [idUser, token]);

	return (
		<>
			<div className={"h-650"}>
				<div className={"flex justify-between"}>
					<div className={"m-0 flex items-end"}>
						<p className={"text-xs text-gray text-end"}>{t("weight_graph_loading")}:</p>
						<p className={"text-sm ml-1 md:ml-2 leading-4"}>{loading}</p>
					</div>
					<div className={"m-0 flex items-end"}>
						<p className={"text-xs text-gray"}>{t("weight_graph_loss")}:</p>
						<p className={"text-sm ml-1 md:ml-2 leading-4"}>{dietPhase}</p>
					</div>
					<div className={"m-0 flex items-end"}>
						<p className={"text-xs text-gray"}>{t("weight_graph_average")}:</p>
						<p className={"text-sm ml-1 md:ml-2 leading-4"}>{onAverage}</p>
					</div>
					<div className={"m-0 flex items-end"}>
						<p className={"text-xs text-gray"}>{t("weight_graph_stabilize")}:</p>
						<p className={"text-sm ml-1 md:ml-2 leading-4"}>
							{stabilizationWeight}
						</p>
					</div>
				</div>
				<div className={"relative shadow h-5/6 w-full rounded-xl mt-5 p-3"}>
					<Line options={options} data={data}/>
				</div>
			</div>
		</>
	);
}
